<template>
  <div class="email-window-container">
    <!-- below notification needs to be replaced by proper validation and HbGlobalNotification aviary component later, just adding this as quick/temporary fix because the previous way was not passable visually -->
    <div v-if="error" class="ma-4">
      <hb-notification
        type="error"
        v-model="error"
      >
        All fields are required.
      </hb-notification>
    </div>
    <v-card flat class="email-window">
      <v-card-title
        class="title"
        primary-title
        v-if="interactiveMode === undefined || !interactiveMode"
      >
        <span>Send Email</span>
        <v-row justify="end">
          <hb-btn icon tooltip="Close" @click="onClose" active-state-off>mdi-close</hb-btn>
        </v-row>
      </v-card-title>
      <v-divider v-if="interactiveMode === undefined || !interactiveMode"></v-divider>
      <v-card-text class="pl-3 py-0 pr-0 hide-overflow email-window-content">
        <v-row>
            <v-col cols="12" class="pa-0" >
              <hb-form label="Template" description="Select the Template">
                <HbAutocomplete v-model="templateName" :items="templateNames" v-validate="'required|max:45'" item-text='name'
              item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box" data-vv-as="Autocomplete Box"
              :error="errors.has('default.autocomplete-box')" placeholder="Select Templates" class="mt-2 pl-1"
              @change="appendtemplate($event)" @click:clear="clearTemplate" />
              </hb-form>
            </v-col>
          </v-row>
        <v-row class="mr-0 email-window-meta" >
          <v-col cols="12" class="pa-0" >
            <hb-form label="To">
                  <hb-combobox
                      :select="true"
                      v-model="selectedEmails"
                      :items="emailIdList"
                      v-validate.disable="'required|max:45'"
                      data-vv-scope="default"
                      data-vv-as="To"
                      id="To"
                      name="To"
                      label="Select Emails"
                  ></hb-combobox>
                      <!-- :error="errors.collect('default.journey2').length > 0" -->
              </hb-form>
          </v-col>
          <!-- <v-col class="pa-0" cols="3">
            <div class="email-label">
              <div class="pt-3">
                <span class="font-weight-medium body-2 ml-6">To</span>
              </div>
            </div>
          </v-col>
          <v-col class= "py-0" cols="9">
            <v-text-field readonly solo flat v-model="to" style="height: 30px;" class="pt-0 pb-1" ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col> -->
        </v-row>

        <v-row class="mr-0 email-window-meta">
<v-col cols="12" class="pa-0">
              <hb-form label="Subject" description="Select the space that this communication is in reference to. Select 'Tenant' if no space reference is required.">
                <div class="d-flex align-left">
                  <space-selection-dropdown :setSelection="setSelectionFlag" :propertyView="propertyid" :from="'send-email'" :mainViewOptions="spaceListViewOptions" @spaceSelectionChanged="onSelectedSpace" @setDefaultSpaceValue="onSelectedSpace">
                        </space-selection-dropdown>
  					<HbTextField
  							v-model="Subject"
  							v-validate="'required|max:100'"
  							data-vv-scope="default"
  							data-vv-name="subject"
  							data-vv-as="Subject"
  							style="width:320px;  margin-left: 10px;"
                              class="pt-2"
  							placeholder="Enter Subject"
  						/>
  						</div>
              </hb-form>

            </v-col>

        </v-row>
        <!-- <v-row class="mr-0 email-window-meta">

          <v-col class="pa-0" cols="3">
            <div class="email-label-subject">
              <div class="pt-3">
                <span class="font-weight-medium body-2 ml-6">Subject</span>
              </div>
            </div>
          </v-col>
          <v-col class= "py-0" cols="9">
            <span>Select the space that this communication is in reference to. Select 'Tenant' if no space reference is required.</span>
                <div class="d-flex align-left">
                    <v-select
                      :items="spaceListViewOptions"
                      item-text="Unit.number"
                      item-value="Unit.number"
                      return-object
                      background-color="white"
                      outlined
                      dense
                      hide-details
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="spaceListView"
                      style="width:120px;"
                      id="spaceListView"
                    >

                      <template v-slot:selection="data">
                        <div class="hb-input-overflow d-flex align-center">
                          <hb-icon color="#101318" class="pr-1" v-if="data.item.Unit.type === 'tenant'">mdi-account</hb-icon>
                          <hb-icon color="#101318" class="pr-1" v-if="data.item.Unit.type === 'storage'" mdi-icon="mdi-facility-custom-3"></hb-icon>
                          <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'parking'">mdi-truck</hb-icon>
                          <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'company'">mdi-domain</hb-icon>
                          <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'residential'">mdi-home</hb-icon>
                          {{ data.item.Unit.number }}
                        </div>
                      </template>
                      <template v-slot:item="data">
                        <hb-icon color="#101318" class="pr-1" v-if="data.item.Unit.type === 'tenant'">mdi-account</hb-icon>
                        <hb-icon color="#101318" class="pr-1" v-if="data.item.Unit.type === 'storage'" mdi-icon="mdi-facility-custom-3"></hb-icon>
                        <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'parking'">mdi-truck</hb-icon>
                        <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'company'">mdi-domain</hb-icon>
                        <hb-icon color="#101318" class="pr-1" v-else-if="data.item.Unit.type === 'residential'">mdi-home</hb-icon>
                        {{ data.item.Unit.number }} 
                      </template>
                    </v-select>

  					<HbTextField
  							v-model="Subject"
  							v-validate="'required|max:100'"
  							data-vv-scope="default"
  							data-vv-name="subject"
  							data-vv-as="Subject"
  							style="width:320px;  margin-left: 10px;"
                              class="pt-2"
  							placeholder="Enter Subject"
  						/>
  						</div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->


        <!-- <v-row class="mr-0 email-window-meta" v-if="attachments.length">
          <v-col class="pa-0" cols="3">
            <div class="email-label">
              <div class="pt-3">
                <span class="font-weight-medium body-2 ml-6">Subject</span>
              </div>
            </div>
          </v-col>
          <v-col class= "py-0" cols="9">
            <v-text-field v-model="Subject" style="height: 30px;" class="pt-0 pb-1" ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
        <!-- <v-row class="mr-0">
          <v-col class="pa-0" cols="3">
            <div class="email-label">
              <div class="pt-3">
                <span class="font-weight-medium body-2 ml-6">From</span>
              </div>
            </div>
          </v-col>
          <v-col class= "py-0" cols="9">
            <v-select :items="from" style="height: 30px;" class="pt-0 pb-1"></v-select>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row> -->
		<v-row class="pr-3">
          <v-col class="pa-0" cols="12">
			<rich-text-editor 
			v-model="Message"
			:proseHeight="interactiveMode ? textareaStyle + 'px': ''"
			/>
      <!-- <div v-else style="max-height: 420px; height: 420px; overflow: auto;"  class="pa-3">
                                                <div style="color:black; font-family: 'Graphik Web', sans-serif;" v-html="Message"></div>
                                                </div> -->
		  </v-col>
        </v-row>
        <v-row class="mr-2 ml-2 pt-2 pb-2">
          <div v-if="$vuetify.breakpoint.xs">
            <hb-btn
           color="secondary"
           @click="showMergeFields = !showMergeFields"
           >
           Add Merge Fields
           </hb-btn>
          </div>
          <div v-else>
            <hb-link v-show="showMergeFields !== true" @click="showMergeFields = true" >
            Show Available Merge Fields
            <hb-tooltip>

              <template v-slot:body>
                Use Merge Fields in your message to insert
                <br />data about your tenants. By using Merge
                <br />Fields, you can send automated messages
                <br />to your tenants without having to manually
                <br />enter their information every time. Merge
                <br />Fields appear in brackets.
                <br /><br />
                In order to use a merge field simply copy
                <br />and paste the text in brackets and add into
                <br />your message.
                <br /><br />
                Example: Hello [Tenant.FirstName] [Tenant.LastName],
                <br />you owe [Tenant.TotalPastDue]. Please pay by
                <br />[Tenant.RentDueDate].
              </template>
            </hb-tooltip>
          </hb-link>

          <hb-link v-show="showMergeFields === true" @click="showMergeFields = false">
            Hide Available Merge Fields
          </hb-link>
          <merge-fields v-if="showMergeFields === true" />
          </div>  
        </v-row>
      </v-card-text>
<v-divider></v-divider>
      <v-card-actions class="email-window-actions pt-3 pb-2">
       
         <hb-menu>
              <template v-slot:menu-activator>
                  <v-btn @click="toggleList" icon> <v-icon>mdi-paperclip</v-icon></v-btn>
                  <hb-btn icon tooltip="Include one-time payment link" mdi-code="mdi-currency-usd" @click="addOneTimePaymentLink"></hb-btn>            
              </template>
                    <v-list v-if="showList">
                        <v-list-item class="pointer-cursor" @click="showFile = true">
                                          Upload
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(0)">                                           
                                          Generate Documents (Non-Signature)                                            
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(1)">                                           
                                          Generate Documents (Signature)                                            
                        </v-list-item>
                         <v-list-item class="pointer-cursor" @click="openUploadDocs(2)">                                           
                                          Invoices                                           
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(3)">                                           
                                          Receipts                                           
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(4)">                                           
                                          Tenant Documents                                            
                        </v-list-item> 
                        <v-list-item class="pointer-cursor" @click="removeattachment()">                                           
                                          Remove Attachment                                           
                        </v-list-item>
                    </v-list>
        </hb-menu>
        <hb-chip v-if="this.attachments.length>0"> {{this.attachmentsName}}</hb-chip>
        <hb-chip v-else-if="this.existingDoc"> {{docName()}}</hb-chip>
        <!-- {{("        "+this.attachments.length+"1"+this.existingDoc+"2")}} -->
        <!-- <v-btn icon> <v-icon>mdi-plus</v-icon>  </v-btn>
        <v-menu offset-y v-model="payNow">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <hb-btn icon tooltip="Add" :active="payNow">mdi-plus</hb-btn>
            </span>
          </template>
          <v-list>
            <v-list-item @click="addPayNowLink">
              <v-list-item-title>Pay Now Link</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-spacer></v-spacer>
          <hb-link class="mr-2" @click="onClose()">Cancel</hb-link>
          <hb-btn class="mr-2" color="primary" @click="SendEmail" :loading="isLoading($options.name)" :disabled="this.emailIdList.length === 0">Send</hb-btn>
      </v-card-actions>
    </v-card>
    <AttachDocsUploads v-if="openUploadDocsStatus" @close="updateUpload" :uploadDocumentAttachments="uploadDocumentAttachments" :attachSignLink="attachSignLink" :contactid="contactId" :propertyid="propertyid" :leases="leases" :selectedTypeOfDoc="selectedTypeOfDoc"></AttachDocsUploads>
    <add-file
      :show="showFile"
      :onClose='onFileClose'
      :getAttachments="getAttachments"
    >
    </add-file>
    <div v-if="$vuetify.breakpoint.xs">     
       <BottomSheetSection  @addMergeField="addMergeField" @onCloseClick="showMergeFields = !showMergeFields" :showMergeFields="showMergeFields"></BottomSheetSection>
    </div>
  </div>
</template>

<script>
import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';
import addFile from './AddFile';
import RichTextEditor from '../assets/RichTextEditor.vue';
import AttachDocsUploads from './UploadAttachEmail.vue'
import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import MergeFields from "../includes/MergeFields";
import BottomSheetSection from '../settings/CommunicationTemplates/TemplateManager/BottomSheetSection.vue';

export default {
  name:'SendEmail',
  data () {
      return {
        showMergeFields: false,
      templateName : '',
      templateNames: [],
      existingDoc:"",
      selectedTypeOfDoc:null,
      openUploadDocsStatus:false,
      attachments:[],
      showFile: false,
      Message: '',
      Subject:'',
      Space:'',
      error:false,
      textareaStyle: '',
      payNow: false,
      pay_message: '',
      showList: false,
      link: '',
      attachmentsName:'',
      buttonHtml: null,
	  spaceListViewOptions:[],
      spaceListView: {
        id: null,
        Unit: {
          number: 'Tenant',
          type: 'tenant',
        }
      },
      selectedEmails:[],
      selectedEmailIds:[],
      emailIdList:[],
      setSelectionFlag:false,
    }
  },
  components: {
    'add-file': addFile,
    'rich-text-editor': RichTextEditor,
    'AttachDocsUploads': AttachDocsUploads,
    SpaceSelectionDropdown,
    MergeFields,
    BottomSheetSection
  },
  props: ['nationalAccount','properties','contactId','to','subject','Message','spaceListViewOptions','onClose','interactiveMode','textarea_height', 'onChatError','contactEmail','leases','propertyid','property_id'],
  mixins: [ notificationMixin ], 
  watch:{
      //  spaceListView: function (newValue) {
      // added to resolve duplicate bugs when chat window is closed and opened 
      // this.$nextTick(()=>{
      //   this.onSelectedSpace(newValue)
      // })
    // },
  },
  created () {
    this.setEmailsList()
    this.selectedEmails.push(this.emailIdList[0])
    this.Subject = this.$props.subject;
    this.Message = this.$props?.Message ?? '';
    this.textareaStyle = this.$props.textarea_height
    this.spaceListView =  this.spaceListViewOptions[1] ?? this.spaceListViewOptions[0]
    this.setSelectionFlag = this.spaceListViewOptions.length > 1
    console.log(this.contactEmail);
    console.log(this.propertyid);
  },
  beforeDestroy(){
      this.onChatError('')
  },
  mounted(){ 
    this.getBaseTemplateData()
  },
  methods: {
    clearTemplate() {
      this.Subject = ''
      this.Message = ''
    },
    async addMergeField(txt) {
          this.Message =   this.Message === undefined ? txt : this.Message + txt
        },
    async getBaseTemplateData() {
        const templates = await this.getAllTemplatesWithId(this.properties);
        const combinedTemplates = [].concat(...templates);
        const templateFilterd =templates.filter(template => template.base_template_id === null)
        this.templateNames = templateFilterd.filter(template => template.type === 'email').map(template => ({ name: template.name, template_id: template.template_id }));
        },
        async getAllTemplatesWithId(properties) {
        const propertyIds = properties.map(property => property.id);
        return await this.getTemplatesById(propertyIds);
        },
        async getTemplatesById(propertyIds) {
            try {
                const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
                const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
                return response.templates;
            } catch (error) {
                console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
                throw error; // Rethrow the error to propagate it up
            }
        },
        async appendtemplate(id) {
        try {
            await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
              if (res.template.body && res.template.subject) {
                this.Message = res.template.body
                this.Subject = res.template.subject
              }
              else {
                this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
              }
            })
      } catch (error) {
        throw error;
      }
    },
    docName(){
      if(this.existingDoc.src){
     let docname =  this.existingDoc.name ? this.existingDoc.name : this.existingDoc.DocumentType.name
      const parts = this.existingDoc.src.split(".");
      const fileExtension = parts.length > 1 ? parts.pop().toLowerCase() : ''   
          if (fileExtension.length<5) {
            if(!docname.endsWith(`.${fileExtension}`)){
                return  `${docname}.${fileExtension}`
            }else{
               return docname
               }
          } else {
            return docname
          }
        }else{
          let fileArray = []
          fileArray.push(this.existingDoc)
          this.attachments = fileArray;
          this.getAttachmentNames()
          // return this.existingDoc.name
        }
    },
    getAttachmentNames(){
      this.attachmentsName='';
      console.log(this.attachments.length);
      if(this.attachments.length==1){
  console.log(this.attachments[0].name);
  this.attachmentsName= this.attachments[0].name;
      }else if(this.attachments.length > 1){
        for (let i = 0; i < this.attachments.length; i++) {
  console.log(this.attachments[i].name);
  this.attachmentsName= 'Files ('+this.attachments.length+')'
  
}
      }
    },
    removeattachment(){
      this.attachments=[]
      this.existingDoc=""
    },
    openUploadDocs(x){
      console.log(x);
         this.openUploadDocsStatus = true
         this.selectedTypeOfDoc = x
    },
    updateUpload(){
         this.openUploadDocsStatus = false
    },
    toggleList() {
      this.showList = true
      // this.showList = !this.showList;
    },
    onFileClose () {
      this.showFile = false
    },
    addPayNowLink(){
        this.Message += '\r\n\r\n[PayNow]Click here to pay your bill[/PayNow]';
    },
    async addOneTimePaymentLink() {
      this.showList=false
      let data = {
        unit_number : this.Space,
        communication_type : 'Email'
      }
        let response = await api.get(this, api.CONTACTS + this.contactId + '/one-time-link', data);
        this.link = response.link;
        this.buttonHtml = `<a target="_blank" rel="noopener noreferrer nofollow" href="${this.link}">Pay Now</a>`
        if(this.Message == null){
          this.Message = '';
        }
        this.Message += `${this.buttonHtml}`
        
    },
    addDirectionsLink(){
        //id is property_id
        this.Message += '\r\n\r\n[Directions|~id:YgBdxQlBwy]Click here get directions[/Directions]';
    },
    getAttachments (attachments) {
      this.removeattachment()
      this.attachments = attachments;
      console.log(this.attachments)
      this.getAttachmentNames()
      this.onFileClose();
    },
    attachSignLink(item){
      this.Message?this.Message += 'Please click the link to sign your documents '+ item :this.Message = 'Please click the link to sign your documents '+ item;
      console.log(item,"itemUrl");
      this.openUploadDocsStatus= false
    },
    uploadDocumentAttachments(doc){
      this.removeattachment()
      this.existingDoc = doc
      this.openUploadDocsStatus= false
    },
    async upload () {
            if(this.attachments.length){
        let body = {
          document_type: this.type,
          document_type_id: this.document_type_id
      }
      let response = await api.postFile(this,  api.CONTACTS + this.contactId + '/upload', body, this.attachments);
      return response
      }else{
      return {
        uploads:{
              id:this.existingDoc.id,
              mimetype:this.existingDoc.mimetype
        }
      }
      }
    },
    onErrorClose () {
      this.error = false;
    },
   onSelectedSpace(spacenumber) {
      console.log("the selected space is", spacenumber.Unit.number);
      console.log("spaceListView options is ",this.spaceListView.Unit.number);
       this.Space = spacenumber.Unit.number;
    },
    async SendEmail () {
      
      if (this.Message.replace(/<\/?[^>]+(>|$)/g, "").trim() !== '' && this.Subject.trim() !== '' && this.selectedEmails.length > 0) {
        var data = {
          message: this.Message,
          subject: this.Subject,
          space: this.Space,
          send_email: true,
          sms: [],
          attachments: this.attachments,
          upload_id: null,
          primary_contact_id:this.contactId,
          property_id: this.nationalAccount ? this.properties[0].id : this.property_id
        }
        try {          
          if (this.attachments.length || this.existingDoc) {
            let fileUploadResponse = await this.upload();
            data.upload_id= []
            if(fileUploadResponse.uploads.id){
              data.upload_id.push(fileUploadResponse.uploads.id)
            }else{
              for(let i=0; i<fileUploadResponse.uploads.length; i++ ){
                data.upload_id.push(fileUploadResponse.uploads[i].id)
              }
            }
            if(this.attachments.length){
              if(fileUploadResponse.uploads.id){
                data.attachments[0].content_type = fileUploadResponse.uploads.mimetype
              }else{
                for(let i=0; i<fileUploadResponse.uploads.length; i++){
                  data.attachments[i].content_type = fileUploadResponse.uploads[i].mimetype
                }
              }
            } else{
              data.src = this.existingDoc.src
              data.docName= this.docName()
              data.attachments = [
                {
                  "content_type":fileUploadResponse.uploads.mimetype ? fileUploadResponse.uploads.mimetype : "application/pdf"
                }
              ]
            }  
        } 
          // let response
      let idArray = this.getContactIds()
      idArray = idArray.filter((value, index, self) => 
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      );
          await Promise.all(idArray.map(item => this.handleSendEmail(item, data)))
          this.$emit('interaction_saved', data);
          EventBus.$emit('interaction_saved');
          this.Message = '';
        } catch(err) {
          console.log(err);
              this.onChatError('The Email attempted either does not exist, does not allow sending email, or is blocked by a networking issue.')
        }
      } else {
        this.error = true
      }
},
    async handleSendEmail(emailList, data){
      data.contact_type = emailList.email.split('(')[1].toLowerCase().replace(')','')
      try {
    return await api.post(this, api.CONTACTS + emailList.id + '/send-message', data);
  } catch (err) {
    console.error("Error in handleSendEmail:", err);
    throw err;
  }
    },
    setEmailsList(){
      this.emailIdList = Array.from(new Set(this.contactEmail.map(emailObject=> emailObject.email))); // remove duplicates
      console.log("================================================================ email list: " + this.emailIdList);
      let emailFilterList = []
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      // let allEmailValid = true;
      this.emailIdList.forEach(email => {
        if (email.match(emailRegex)) {
          emailFilterList.push(email)
        } else {
          
        }
      });
      this.emailIdList = emailFilterList
      console.log("================================================================================================================================ emailFilterList",this.emailIdList.length);
      },
    getContactIds(){
      let ids = this.contactEmail.filter(item => this.selectedEmails.indexOf(item.email) != -1).map(item => item)
      return ids
    }
  }
}
</script>


<style scoped>
.email-label {
  background-color: #F9FAFB;
  height: 48px;
  color: #101318;
}

.email-label-subject {
  background-color: #F9FAFB;
  height: 90px;
  color: #101318;
}

.hide-overflow {
  overflow: hidden;
}
.email-window-container,
.email-window{
  flex: 1;
}
.email-window-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.email-window-meta{
  flex:  0 1;
}
.email-window-textarea{
  background: white;
  flex: 1 0;
}



</style>
